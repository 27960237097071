import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { ImageValidation } from "../Validation/ImageValidation";
import { passportApi, passportNumberApi, checkKycStatus } from "./Apis";
import * as myConstList from "../Common/BaseUrl";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import countryList from "react-select-country-list";
import Rejectreason from "./Rejectreason";
const baseUrl = myConstList.baseUrl;
const imgUrl = myConstList.imgUrl;

function Passport(props) {
  const { t } = useTranslation();
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [bimage, setBImage] = useState("/assets/img/icon2.png");
  const [passportStatus, setPassportStatus] = useState("0");
  const [passportNumberStatus, setPassportNumberStatus] = useState("0");
  const [passportImage, setPassportImage] = useState("");
  const [passportImageError, setPassportImageError] = useState("");
  const [useruploadPassportImage, setUseruploadPassportImage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportNumberError, setPassportNumberError] = useState("");
  const [docType, setDocType] = useState("Passport");
  const [passportImageBack, setPassportImageBack] = useState("");
  const [passportImageBackError, setPassportImageBackError] = useState("");
  const [countryIsoError, setCountryIsoError] = useState("");
  const [useruploadPassportBackImage, setUseruploadPassportBackImage] =
    useState("");
  const [openBackImg, setopenBackImg] = useState(false);

  const [value, setValue] = useState("");
  const [defaultValues, setDefaultValues] = useState(null);
  const [countryOption, setCountryOption] = useState([]);
  const options = useMemo(() => countryList().getData(), []);

  console.log(options, "options");

  const changeHandler = (value) => {
    console.log(value, "value");
    setValue(value);
    if (!value) {
      setCountryIsoError("This field is required");
      return;
    }
    setCountryIsoError("");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent", // background color of the input field
      borderColor: state.isFocused ? "grey" : "grey", // border color of the input field
      boxShadow: state.isFocused ? "0 0 0 1px grey" : "none", // box shadow when focused
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "grey"
        : state.isFocused
          ? "#fff"
          : "white", // background color of options
      color: state.isSelected ? "white" : "black", // text color of options
      padding: 10, // padding of options
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // text color of the input when searching
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // placeholder color if needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // color of the selected value in the input field
    }),
  };

  useEffect(() => {
    axios.post(baseUrl + "/frontapi/country_list", {}).then((response) => {
      console.log(response);
      setCountryOption(response.data.data)
    })
  }, [])

  useEffect(() => {
    if (props.userinfo.length > 0) {
      console.log(props.userinfo[0]);
      setPassportNumberStatus(
        props.userinfo[0].document_number_status &&
        props.userinfo[0].document_number_status
      );
      setDocType(
        props.userinfo[0].doc_type ? props.userinfo[0].doc_type : "Passport"
      );
      setPassportStatus(props.userinfo[0].passport_status);
      props.userinfo[0].passport_image &&
        setImage(
          imgUrl + "/static/upload/" + props.userinfo[0].passport_image
        );
      if (
        props.userinfo[0].passport_image_back &&
        props.userinfo[0].doc_type == "National Identity Card"
      ) {
        setBImage(
          imgUrl + "/static/upload/" + props.userinfo[0].passport_image_back
        );
      } else {
        setBImage("/assets/img/icon2.png");
      }
      setRejectionReason(props.userinfo[0].d5_rejectResion);
      setPassportNumber(props.userinfo[0].passport_number);
      if (props.userinfo[0].country_code_iso) {
        setValue({
          value: props.userinfo[0].country_code_iso,
          label: props.userinfo[0].country_name ,
        });
      }

      setDefaultValues({
        value: props.userinfo[0].country_code_iso,
        label: props.userinfo[0].country_name,
      });
      setopenBackImg(
        props.userinfo[0].doc_type == "National Identity Card" ? true : false
      );
    }
  }, [props]);

  const handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(name," vv1 ",value);
    const isInvalid = /^0+$/.test(value) || /^0\d+/.test(value);
    console.log(docType," isInvalid ",isInvalid);
    if (name == "docType") {
      setUseruploadPassportImage("");
      setUseruploadPassportBackImage("");
      setDocType(e.target.value);
      setPassportNumber("");
      setPassportNumberError("");
      if (value == "Driving Licence") {
        setopenBackImg(false);
      }
      if (value == "Passport") {
        setopenBackImg(false);
      }
      if (value == "National Identity Card") {
        setopenBackImg(true);
      }
    }
    if (name == "passportNumber") {
      setPassportNumber(e.target.value.toUpperCase());
      if (e.target.value === "") {
        setPassportNumberError("This field is required");
        return false;
      }
      if (docType == "Passport") {
        setopenBackImg(false);
        if (isInvalid || !(e.target.value.length >= 7 && e.target.value.length <= 12)) {
          setPassportNumberError(
            "Please enter a valid passport number without any space"
          );
          return false;
        }
      }
      if (docType == "Driving Licence") {
        setopenBackImg(false);
        if (!(e.target.value.length >= 9 && e.target.value.length <= 16)) {
          setPassportNumberError(
            "Please enter a valid driving licence number without any space"
          );
          return false;
        }
      }
      if (docType == "National Identity Card") {
        setopenBackImg(true);
        if (!(e.target.value.length >= 9 && e.target.value.length <= 16)) {
          setPassportNumberError(
            "Please enter a valid national identity number without any space"
          );
          return false;
        }
      }
      setPassportNumberError("");
      setPassportNumber(value)
      
    }

    if (name == "passportImage") {
      setPassportImage(e.target.files[0]);
      let error = ImageValidation("Passport", e.target.files[0]);
      setPassportImageError(error);
      e.target.files[0]
        ? setUseruploadPassportImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadPassportImage("");
    }
    if (name == "passportImageBack") {
      setPassportImageBack(e.target.files[0]);
      let error = ImageValidation("Passport", e.target.files[0]);
      setPassportImageBackError(error);
      e.target.files[0]
        ? setUseruploadPassportBackImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadPassportBackImage("");
    }
  };

  const handleSubmit = async () => {
    console.log("passport sub", passportNumber, props.userinfo[0].passport_number);

   

    // if (!passportNumber) {
    //   setPassportNumberError("This field is required");
    //   return false;
    // }
    if (docType === "Passport") {
      if(props.userinfo[0].passport_number == null) {
        toast.error(t("Please submit indentity number first"));
        return
      }
      if (passportNumber && !(passportNumber.length >= 7 && passportNumber.length <= 12)) {
        setPassportNumberError(
          "Please enter a valid passport number without any space"
        );
        return false;
      }
    }
    if (docType === "Driving Licence") {
      if(props.userinfo[0].passport_number == null) {
        toast.error(t("Please submit indentity number first"));
        return
      }
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid driving licence number without any space"
        );
        return false;
      }
    }
    if (docType === "National Identity Card") {
      if(props.userinfo[0].passport_number == null) {
        toast.error(t("Please submit indentity number first"));
        return
      }
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid national identity number without any space"
        );
        return false;
      }
      if (!passportImageBack) {
        setPassportImageBackError("This field is required");
        return false;
      }
      if (passportImageBackError) {
        return false;
      }
    }
    if (!passportImage) {
      setPassportImageError("This field is required");
      return false;
    }
    if (passportImageError) {
      return false;
    }
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var data = new FormData();
    data.append("docType", docType);
   // data.append("passportNumber", passportNumber);
    data.append("passportImage", passportImage);
    data.append("passportImageBack", passportImageBack);

    let result = await passportApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      toast.success(t(`${docType} uploaded successfully`));
      props.resendResult(true);
    } else {
      setPassportNumber("");
      toast.error(t(result.message));
      props.resendResult(true);
    }
  };

  const submitPassportNumber = async (e) => {
    e.preventDefault();

    if (!value) {
      setCountryIsoError("This field is required");
      return;
    }
    console.log("hii", value);

    if (!passportNumber) {
      setPassportNumberError("This field is required");
      return false;
    }
    if (docType === "Passport") {
      if (!(passportNumber.length >= 7 && passportNumber.length <= 12)) {
        setPassportNumberError(
          "Please enter a valid passport number without any space"
        );
        return false;
      }
    }
    if (docType === "Driving Licence") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid driving licence number without any space"
        );
        return false;
      }
    }
    if (docType === "National Identity Card") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid national identity number without any space"
        );
        return false;
      }
    }

    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

   // let countryIso = countryOption.find(val => val.id == value?.value)
   // console.log("CO==> ",countryIso);
    const data = {
      passportNumber: passportNumber,
      docType: docType,
      //countryIso: countryIso.iso,
      country: value.value,
    };

    console.log("DATA==> ",data);

    let result = await passportNumberApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      toast.success(t(result.message));
      props.resendResult(true);
    } else {
      setPassportNumber("");
      toast.error(t(result.message));
      props.resendResult(true);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="passport-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">{t("Identity Proof")}</h6>
              <b className="mt-0">{t("Requirements")}</b>
              <ul className="dit_ul mb-4">
                <li>
                  {t("The photo/scan must be clear, in color and uncompressed.")}
                </li>
                <li>{t("The photo/scan must be not edited and not cropped.")}</li>
                <li>
                  {t("The document must be valid at least 3 months after the verification submission date.")}
                </li>
                <li>{t("Should exactly match the ones in your document.")}</li>
              </ul>
              <div className="row">
                <div className="form-group col-md-6 col-12 ">
                  <div className="mb-3">
                    <label className="form-label">{t("Country")}</label>
                    {console.log(defaultValues, "valuedddd", value)}
                    {props.userinfo &&
                      defaultValues &&
                      defaultValues.value &&
                      options && (
                        <Select
                          options={options}
                          styles={customStyles}
                          defaultValue={defaultValues}
                          isDisabled={true}
                        />
                      )}
                    {props.userinfo &&
                      defaultValues &&
                      !defaultValues.value &&
                      options && (
                        <Select
                          options={options}
                          value={value}
                          onChange={changeHandler}
                          styles={customStyles}
                          isClearable={true}
                          isSearchable={true}
                        />
                      )}
                    {countryIsoError && (
                      <div className="text-danger">{t(countryIsoError)}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{t("Document")}</label>
                    <select
                      value={docType}
                      className="form-control"
                      onChange={handleChange}
                      name="docType"
                      disabled={
                        passportNumberStatus == "0" ||
                          passportNumberStatus == "3"
                          ? false
                          : true
                      }
                    >
                      <option value="Passport">{t("Passport")}</option>
                      {value &&
                        (value?.value === "KR" || value.value === "KP") && (
                          <>
                            <option value="Driving Licence">
                              {t("Driving Licence")}
                            </option>
                            <option value="National Identity Card">
                              {t("National Identity Card")}
                            </option>
                          </>
                        )}
                    </select>
                  </div>
                  <label>{t(docType)} {t("Number")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t(`Enter ${docType} Number`)}
                    name="passportNumber"
                    value={passportNumber}
                    onChange={handleChange}
                    disabled={
                      passportNumberStatus === "0" ||
                        passportNumberStatus === "3"
                        ? false
                        : true
                    }
                    onKeyPress={(event) => {
                      if (!/[A-Za-z0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {passportNumberError && (
                    <div className="text-danger">{t(passportNumberError)}</div>
                  )}

                  <div>
                    {passportNumberStatus === "0" ||
                      passportNumberStatus === "3" ? (
                      <button
                        className="btn btn_man w100px mt-2"
                        onClick={submitPassportNumber}
                      >
                        {t("submit")}
                      </button>
                    ) : (
                      <button className="btn btn_man w100px mt-2" disabled>
                        {t("Submitted")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-6"></div>
                <div className="form-group col-lg-4 col-md-6">
                  <div id="file-upload-form" className="uploader mt-3">
                    <input
                      type="file"
                      id="passportImage"
                      name="passportImage"
                      onChange={handleChange}
                      accept="image/*"
                      capture
                      disabled={
                        passportStatus == "0" || passportStatus == "3"
                          ? false
                          : true
                      }
                    />
                    <label for="passportImage" id="file-drag">
                      <img
                        src={
                          useruploadPassportImage !== ""
                            ? useruploadPassportImage
                            : image
                        }
                        className="img-fluid22"
                        alt="Identity"
                      />
                      <div id="start">
                        <span className="fasi"></span>
                        {docType === "National Identity Card" ? (
                          <div>{t(`Upload ${docType} Front Image`)}</div>
                        ) : (
                          <div>{t(`Upload ${docType} Image`)}</div>
                        )}
                        <div id="notimage" className="hidden">
                          {t("Please select an image")}
                        </div>
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages"></div>
                        <progress
                          className="progress"
                          id="file-progress"
                          value="0"
                        >
                          <span>0</span>%
                        </progress>
                      </div>
                    </label>
                  </div>
                  <p className="mt-0">*{t("only jpeg, jpg & png files supported")}</p>
                  {passportImageError && (
                    <div className="text-danger">{t(passportImageError)}</div>
                  )}
                  <Rejectreason
                    reason={rejectionReason}
                    status={passportStatus}
                  />
                </div>
                {openBackImg ? (
                  <div className="form-group col-lg-4 col-md-6">
                     <div id="file-upload-form" className="uploader mt-3">
                      <input
                        type="file"
                        id="passportImageBack"
                        name="passportImageBack"
                        onChange={handleChange}
                        disabled={
                          passportStatus == "0" || passportStatus == "3"
                            ? false
                            : true
                        }
                      />
                      <label for="passportImageBack" id="file-drag">
                        <img
                          src={
                            useruploadPassportBackImage !== ""
                              ? useruploadPassportBackImage
                              : bimage
                          }
                          className="img-fluid22"
                          alt="Identity"
                        />
                        <div id="start">
                          <span className="fasi"></span>
                          {docType === "National Identity Card" ? (
                            <div>Upload {docType} Back Image</div>
                          ) : (
                            <div>Upload {docType} Image</div>
                          )}
                          <div id="notimage" className="hidden">
                            Please select an image
                          </div>
                        </div>
                        <div id="response" className="hidden">
                          <div id="messages"></div>
                          <progress
                            className="progress"
                            id="file-progress"
                            value="0"
                          >
                            <span>0</span>%
                          </progress>
                        </div>
                      </label>
                    </div> 
                     <p className="mt-0">
                      *only jpeg, jpg & png files supported
                    </p> 
                    {passportImageBackError && (
                      <div className="text-danger">
                        {t(passportImageBackError)}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {passportStatus !== "2" && (
                <button
                  className="btn btn_man w100px"
                  onClick={handleSubmit}
                  disabled={btnDisable || passportStatus == "1"}
                >
                  {btnDisable || passportStatus == "1" ? t("Submitted") : t("submit")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Passport;
