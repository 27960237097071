import React from 'react'
import { useTranslation } from "react-i18next";

function Rejectreason(props) {
    const { t } = useTranslation();
    console.log("rejectreason",props)
    return (
        <>
            {props.status == "3" && <div style={{ color: "red" }}>
               {t("Status")}:- {props.status == "3"?t("Rejected"):t("Verified")}<br/>
               {t("Reason")}:- {props.reason}
            </div>}
            {props.status == "1" && <div style={{ color: "red" }}>
                <h6>{t("Waiting for admin to verify")}</h6>
            </div>}
        </>
    )
}

export default Rejectreason