
//export const baseUrl = "http://192.168.1.40:8000/v1";
//export const imgUrl = "http://192.168.1.40:8000/";

export const baseUrl = "https://apiuat.dbiexchange.com/v1";
export const imgUrl = "https://apiuat.dbiexchange.com";
export const adminbaseUrl = "https://apiuat.dbiexchange.com/";
export const socketUrl = "https://streamuat.dbiexchange.com/";

// export const imgUrl = "https://api.dbiexchange.com";
// export const baseUrl = "https://api.dbiexchange.com/v1";
// export const adminbaseUrl = "https://api.dbiexchange.com/";
// export const socketUrl = "wss://stream.dbiexchange.com/";

