import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ImageValidation } from '../Validation/ImageValidation'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import * as myConstList from "../Common/BaseUrl";
import { aadhaarImageApi, aadhaarNumberApi } from './Apis'
import { ThirdPartyApi } from './ThirdPartyApis'
import { ConsoleView } from 'react-device-detect';
import Rejectreason from './Rejectreason';
const baseUrl = myConstList.baseUrl;

const Aadhaar = (props) => {
    const { t } = useTranslation();
    const [image, setImage] = useState("/assets/img/icon2.png")
    const [image2, setImage2] = useState("/assets/img/icon2.png")
    const [userInfo, setUserInfo] = useState([])
    const [userInfoLength, setUserInfoLength] = useState(0)
    const [aadhaarFront, setAadhaarFront] = useState("")
    const [useruploadAadhaarBack, setUseruploadAadhaarBack] = useState("")
    const [useruploadAadhaarFront, setUseruploadAadhaarFront] = useState("")
    const [aadhaarBack, setAadhaarBack] = useState("")
    const [aadhaarNumber, setAadhaarNumber] = useState("")
    const [aadhaarNumberError, setAadhaarNumberError] = useState("")
    const [aadhaarFrontError, setAadhaarFrontError] = useState("")
    const [aadhaarBackError, setAadhaarBackError] = useState("")
    const [verification_code, setVerification_code] = useState("")
    const [verification_code_Error, setVerification_code_Error] = useState("")
    const [timerText, setTimerText] = useState("")
    const [otpStatus, setOtpStatus] = useState(false)
    const [clientId, setClientId] = useState("")
    const [counter, setCounter] = useState(0)
    const [textForCode, setTextForCode] = useState("Get Code")
    const [codeBtnDisable, setCodeBtnDisable] = useState("")
    const [aadhaarNumberStatus, setAadhaarNumberStatus] = useState(0)
    const [aadhaarImageStatus1, setAadhaarImageStatus1] = useState("")
    const [aadhaarImageStatus2, setAadhaarImageStatus2] = useState("")
    const [rejectionReason, setRejectionReason] = useState("")
    const [btnDisable, setBtnDisable] = useState(false)
    // The multiplication table
var d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    ];
    // permutation table p
    var p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
    ];
    // inverse table inv
    var inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

    useEffect(() => {
        if (props.userinfo.length > 0) {
            console.log("props.userinfo.length", props.userinfo)
            setUserInfo(props.userinfo)
            setUserInfoLength(props.userinfo.length)
            setAadhaarNumberStatus(props.userinfo[0].doc_type_status)
            setRejectionReason(props.userinfo[0].d1_rejectResion)
            props.userinfo[0].document_number && setAadhaarNumber(props.userinfo[0].document_number)
            props.userinfo[0].i_image && setImage(baseUrl + "/static/upload/" + props.userinfo[0].i_image)
            props.userinfo[0].i_image2 && setImage2(baseUrl + "/static/upload/" + props.userinfo[0].i_image2)
        }
    }, [props])
    function invArray(array) {
        if (Object.prototype.toString.call(array) == "[object Number]") {
          array = String(array);
          console.log(array,"!!!")
        }
        if (Object.prototype.toString.call(array) == "[object String]") {
          array = array.split("").map(Number);
          console.log(array,"!!!")
         
        }
        return array.reverse();
      }
      
      // generates checksum
      function generate(array) {
        
        var c = 0;
        var invertedArray = array.reverse();
      
        for (var i = 0; i < invertedArray.length; i++) {
            console.log(d[c][p[((i + 1) % 8)][invertedArray[i]]])
          c = d[c][p[((i + 1) % 8)][invertedArray[i]]];
    
        }
        return inv[c];
      }
      
      // validates checksum
      function validate(array) {
      
        var c = 0;
        var invertedArray = invArray(array);
      
        for (var i = 0; i < invertedArray.length; i++) {
          c = d[c][p[(i % 8)][invertedArray[i]]];
        }
      
        return (c === 0);
      }
      
      function validateAadhaar(aadhaarString) {
        if (aadhaarString.length != 12) {
          return new Error('Aadhaar numbers should be 12 digit in length');
        }
        if (aadhaarString.match(/[^$,.\d]/)) {
          return new Error('Aadhaar numbers must contain only numbers');
        }
        var aadhaarArray = aadhaarString.split('');
      
        var toCheckChecksum = aadhaarArray.pop();
        
        if (generate(aadhaarArray) == toCheckChecksum) {
          return true;
        } else {
          return false;
        }
      };

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value
        // let error = NumberValidation(name, value)
        if (name == "verification_code") {
            if (value) {
                setVerification_code_Error("")
            }
            else {
                setVerification_code_Error("This field is required")
            }
            setVerification_code(value)
        }
        if (name == "aadhaarNumber") {
            setAadhaarNumber(e.target.value)
            if(value==""){
                setAadhaarNumberError("This field is required")
                return false
            }
          
            if(!(value.length==12)){
                setAadhaarNumberError("Aadhaar number is not valid")
                return false
            }
            if(value[0]==0){
                setAadhaarNumberError("Aadhaar number is not valid")
                return false
            }
            setAadhaarNumberError("")

            // if (value) {
            //     setAadhaarNumberError("")
            
            // }
            // if(!value.length==12){
            //     setAadhaarNumberError("Invalid aadhaar number")
            // }
            // else {
            //     setAadhaarNumberError("This field is required")
            // }
        }
    }
    const getCode = () => {
        if (!aadhaarNumber) {
            setAadhaarNumberError("This field is required")
            return false
        }
        var data = JSON.stringify({
            "id_number": aadhaarNumber
        });
        ThirdPartyApi.aadhaarOtp.data = data

        axios(ThirdPartyApi.aadhaarOtp)
            .then(function (response) {
                if (response.data.success) {
                    setOtpStatus(response.data.message)
                    setClientId(response.data.data.client_id)
                    startCountdown(30)
                    toast.success(response.data.message)
                }
            })
            .catch(function (error) {
                if (!error.response.data.data.valid_aadhaar) {
                    toast.error("Aadhaar number is not valid")
                    return false
                } else {
                    toast.error(error.message)
                }
            });


    }
    const startCountdown = (seconds) => {
        let counter = seconds;
        const interval = setInterval(() => {
            setCounter(counter)
            setCodeBtnDisable(true)
            setTimerText("Resend In")
            counter--;
            if (counter < 0) {
                clearInterval(interval);
                setTextForCode("Resend Code")
                setCodeBtnDisable(true)
                setTimerText("")
            }
        }, 1000);
    }

    const handleImageChange = (e) => {
        let name = e.target.name
        let value = e.target.files[0]
        if (name == 'front') {
            let error = ImageValidation("Aadhaar front", value)
            setAadhaarFrontError(error)
            setAadhaarFront(e.target.files[0])
            e.target.files[0] ? setUseruploadAadhaarFront(URL.createObjectURL(e.target.files[0])) :
                setUseruploadAadhaarFront("")
        }
        if (name == "back") {
            let error = ImageValidation("Aadhaar back", value)
            setAadhaarBackError(error)
            setAadhaarBack(e.target.files[0])
            e.target.files[0] ? setUseruploadAadhaarBack(URL.createObjectURL(e.target.files[0])) :
                setUseruploadAadhaarBack("")
        }
    }

    const handleSubmit = async () => {
        // if (!aadhaarNumber && !verification_code) {
        //     setAadhaarNumberError('This field is required')
        //     setVerification_code_Error('This field is required')
        //     return false
        // }
        // if (!verification_code) {
        //     setVerification_code_Error('This field is required')
        //     return false
        // }
        if (!aadhaarNumber) {
            setAadhaarNumberError('This field is required')
            return false
        }
        if (!(aadhaarNumber.length==12)) {
            setAadhaarNumberError('Aadhaar number is not valid')
            return false
        }
        if (aadhaarNumber[0]==0) {
            setAadhaarNumberError('Aadhaar number is not valid')
            return false
        }
        if (aadhaarNumber && aadhaarNumberError) {
            setAadhaarNumberError('')
        }
        if(!validateAadhaar(aadhaarNumber)){
            setAadhaarNumberError('Aadhaar number is not valid')
            return false
        }
        if (!aadhaarNumber && aadhaarNumberError) {
            return false
        }
         
        setBtnDisable(true)
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let DocNumber = {
            number: aadhaarNumber,
            check: "aadhaar",
        };
        let result = await aadhaarNumberApi(DocNumber, config)
        setTimeout(() => {
            setBtnDisable(false)
        }, 500);
        if (result.status) {
            setAadhaarNumberError('')
            toast.success(result.message)
            props.resendResult(true)
        }
        else {
            toast.error(result.message)
            props.resendResult(true)
        }

    }
    const uploadImage = async (image) => {
        if (!aadhaarFront && !aadhaarBack) {
            setAadhaarFrontError("This field is required")
            setAadhaarBackError("This field is required")
            return false
        }
        if (!aadhaarFront) {
            setAadhaarFrontError("This field is required")
            return false
        }
        if (!aadhaarBack) {
            setAadhaarBackError("This field is required")
            return false
        }
        if (aadhaarBackError || aadhaarFrontError) {
            return false
        }
        setBtnDisable(true)
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const formData = new FormData();
        formData.append("IdentityProof", aadhaarFront);
        formData.append("DrivingLicense", aadhaarBack);

        let result = await aadhaarImageApi(formData, config)
        setTimeout(() => {
            setBtnDisable(false)
        }, 50);
        if (result.status) {
            toast.success("Aadhaar card image uploaded successfully")
            props.resendResult(true)
        }
        else {
            toast.error(result.message)
            props.resendResult(true)
        }
    }

    const IdentitySubmit = () => {
        if (aadhaarNumberStatus == "0") {
            toast.dismiss()
            toast.error("Please save aadhaar number first")
            // setAadhaarNumberError('Please save the document number first')
            return false
        }


        let error = ImageValidation("Aadhaar front", aadhaarFront)
        let errorBack = ImageValidation("Aadhaar back", aadhaarBack)
        setAadhaarFrontError(error)
        setAadhaarBackError(errorBack)
        if (error || errorBack) {
            return false
        }
        const FirstImage = uploadImage(aadhaarFront)
        console.log("aadhaarfrontimage", FirstImage)
    }

    return (
        <>
            <div className='aadhaar-outer' id='proof'>
                <div className='row'>
                    <div className='col-md-3 col-12'>
                    </div>
                    <div className='col-md-9 col-12'>
                        <div className="white_box">
                            <h6 className="position-relative">
                                Aadhaar Card verification
                            </h6>
                            {console.log("this.state", userInfo)}
                            <b className="mt-0">Requirements</b>
                            <ul className="dit_ul mb-3">
                                <li>
                                    The photo/scan must be clear, in color and
                                    uncompressed.
                                </li>
                                <li>
                                    The photo/scan must be not edited and not cropped.
                                </li>
                                <li>
                                    The document must be valid at least 3 months after
                                    the verification submission date.
                                </li>
                                <li>
                                    The full name and date of birth you provided in
                                    the "Personal information" section{" "}
                                </li>
                                <li>
                                    should exactly match the ones in your document.
                                </li>
                                <li>Min size 10 Kb / Max size 1 Mb.</li>
                            </ul>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Aadhaar number</label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        placeholder="Enter Aadhaar Number"
                                        name="aadhaarNumber"
                                        // min="12"
                                        maxLength="12"
                                        value={aadhaarNumber}
                                        onChange={handleChange}
                                        disabled={
                                            aadhaarNumberStatus !== "0"
                                        }
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                    />
                                    {
                                        aadhaarNumberError &&
                                        <div className='text-danger'>{aadhaarNumberError}</div>
                                    }
                                   {btnDisable || aadhaarNumberStatus !== "0"?"": aadhaarNumberStatus !== "2" &&
                                        <div className='save-btn'> <button
                                            className="btn btn_man mt-3"
                                            onClick={handleSubmit}
                                            disabled={btnDisable || aadhaarNumberStatus !== "0"}
                                        >
                                           {btnDisable || aadhaarNumberStatus !== "0"?"Saved":"Save"} 
                                        </button>
                                        </div>}
                                </div>
                                {/* {aadhaarNumberStatus == 0 && <div className="form-group col-md-6">
                                <label>
                                    Verification Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="verification_code"
                                    placeholder="Enter Verification Code"
                                    minLength="2"
                                    maxlength="20"
                                    // disabled={userInfoLength > 0 && userInfo[0].identity_status ? true : false}
                                    value={verification_code}
                                    onChange={handleChange}
                                />

                                {verification_code_Error &&
                                    <div className='text-danger'>
                                        {verification_code_Error}
                                    </div>}
                                <div className="input-group-prepend position-absolute get-code-btn verify-btn">
                                    <button
                                        className="btn btn_man"
                                        id="btnGroupAddon"
                                        type="button"
                                        onClick={getCode}
                                        disabled={counter == 0 ? false : true}
                                    >
                                        {timerText}
                                        {"  "}
                                        {counter == 0
                                            ? textForCode
                                            : counter}
                                    </button>
                                </div>
                            </div>} */}
                            </div>

                            <div className="fs12">
                                <div className="row">
                                    <div className="form-group col-md-4">
                                        <div id="file-upload-form" className="uploader">
                                            <input
                                                type="file"
                                                id="file-upload"
                                                name="front"
                                                onChange={handleImageChange}
                                                // onBlur={this.onBlurIdentityImage}
                                                disabled={userInfoLength > 0 && userInfo[0].identity_status !== "0" && userInfo[0].identity_status !== "3" ? true : false}
                                            />
                                            <label for="file-upload" id="file-drag">
                                                <img
                                                    src={
                                                        useruploadAadhaarFront !== ""
                                                            ? useruploadAadhaarFront
                                                            : image
                                                    }
                                                    className="img-fluid22"
                                                    alt="Identity"
                                                />
                                                <div id="start">
                                                    <span className="fasi"></span>
                                                    <div>Upload Front image</div>
                                                    <div id="notimage" className="hidden">
                                                        Please select an image
                                                    </div>
                                                </div>
                                                <div id="response" className="hidden">
                                                    <div id="messages"></div>
                                                    <progress
                                                        className="progress"
                                                        id="file-progress"
                                                        value="0"
                                                    >
                                                        <span>0</span>%
                                                    </progress>
                                                </div>
                                            </label>
                                        </div>
                                        <p className="mt-0">
                                            *only jpeg, jpg &amp; png files supported
                                        </p>
                                        {aadhaarFrontError &&
                                            <div className='text-danger'>
                                                {aadhaarFrontError}
                                            </div>}
                                            <Rejectreason reason={rejectionReason} status={userInfoLength > 0 && userInfo[0].identity_status} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <div id="file-upload-form" className="uploader">
                                            <input
                                                type="file"
                                                id="file-upload2"
                                                name="back"
                                                onChange={handleImageChange}
                                                disabled={userInfoLength > 0 && userInfo[0].identity_status !== "0" && userInfo[0].identity_status !== "3" ? true : false}
                                            // onBlur={this.onBlurIdentityImage2}
                                            // disabled={
                                            //   disabledIdentity2
                                            //     ? "disabled"
                                            //     : ""
                                            // }
                                            />
                                            <label for="file-upload2" id="file-drag">
                                                <img
                                                    src={
                                                        useruploadAadhaarBack !== ""
                                                            ? useruploadAadhaarBack
                                                            : image2
                                                    }
                                                    className="img-fluid22"
                                                    alt="Identity"
                                                />
                                                <div id="start">
                                                    <span className="fasi"></span>
                                                    <div>Upload Back image</div>
                                                    <div id="notimage" className="hidden">
                                                        Please select an image
                                                    </div>
                                                </div>
                                                <div id="response" className="hidden">
                                                    <div id="messages"></div>
                                                    <progress
                                                        className="progress"
                                                        id="file-progress"
                                                        value="0"
                                                    >
                                                        <span>0</span>%
                                                    </progress>
                                                </div>
                                            </label>
                                        </div>
                                        <p className="mt-0">
                                            *only jpeg, jpg &amp; png files supported
                                        </p>
                                     
                                        {aadhaarBackError &&
                                            <div className='text-danger'>
                                                {aadhaarBackError}
                                            </div>}
                                    </div>
                                </div>
                                {userInfoLength > 0 && userInfo[0].identity_status !== "2" && userInfo[0].identity_status2 !== "2" && <button
                                    className="btn btn_man fs12 w100px"
                                    onClick={IdentitySubmit}
                                    disabled={btnDisable || userInfo[0].identity_status !== "0" && userInfo[0].identity_status2 !== "0" && userInfo[0].identity_status !== "3" && userInfo[0].identity_status2 !== "3"}
                                >
                                   {btnDisable || userInfo[0].identity_status !== "0" && userInfo[0].identity_status2 !== "0" && userInfo[0].identity_status !== "3" && userInfo[0].identity_status2 !== "3"?"Submited":"Submit"} 
                                </button>}

                                {/* {this.imageStatushtml()} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aadhaar